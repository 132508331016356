import styled from "styled-components";

export const HomeContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #2d3a45;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const HomeContent = styled.div`
  max-width: 600px;
  min-width: 300px;
  min-height: 600px;
  width: 30%;
  height: 90%;
  background-color: #d1dfeb;
  color: #2d3a45;
  border-radius: 5px;
  padding: 12px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 600px) {
    width: 100%;
    height: 100%;
    max-width: 100%;
    min-width: 100%;
  }
`;

export const HeaderContent = styled.div`
  width: 100%;
  height: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20%;
  margin-top: 30%;

  & > h2 {
    font-size: 16px;
    & > a {
      text-decoration: none;
      color: #2d3a45;
      transition: all linear 0.2s;
      &:hover {
        opacity: 0.6;
      }
    }
  }
  & > div {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;
    & > div {
      border-radius: 100%;
      border: #fff 4px solid;
      width: 130px;
      height: 130px;
      z-index: 100;
      position: absolute;
    }
    & > img {
      width: 140px;
      height: 140px;
    }
  }
`;

export const OptionsButtons = styled.button`
  width: 280px;
  height: 40px;
  border: #2d3a45 4px solid;
  border-radius: 10px;
  background-color: transparent;
  transition: all linear 0.2s;
  cursor: pointer;
  margin-bottom: 8px;

  @media (max-width: 600px) {
    width: 80vw;
    height: 45px;
  }
  &:last-child {
    margin-bottom: 0px;
  }

  &:hover {
    opacity: 0.6;
  }
  & > a {
    width: 100%;
    height: 100%;
    text-decoration: none;
    color: #2d3a45;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 1.5;
  }
`;

export const OptionsContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const FooterContent = styled.footer``;
