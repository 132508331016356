import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`

  body ,html{
    margin: 0px;
    padding: 0px;
    font-family:  Open Sans, Helvetica, Sans-Serif;
    box-sizing:border-box;
    overflow-y:none;
  }
  h1,h2,h3,h4,h5,h6,p,span{
    margin: 0px;
    padding:0px;
  }
  button , input , textarea{
    padding:0px;
    margin:0px;
    outline: none;
    border:none;
  }


`;
