import React from "react";
import { BrowserRouter } from "react-router-dom";
import { GlobalStyle } from "./globalStyled";
import { Home } from "./pages/home";
import Routes from "./routes/index";

const App: React.FC = () => (
  <BrowserRouter>
    <Home />
    <GlobalStyle />
  </BrowserRouter>
);

export default App;
