import {
  HeaderContent,
  HomeContainer,
  HomeContent,
  FooterContent,
  OptionsContent,
  OptionsButtons,
} from "./styles";
import logoDefault from "../../assets/LogoDefaultSize.png";

export const Home: React.FC = () => {
  return (
    <HomeContainer>
      <HomeContent>
        <HeaderContent>
          <div>
            <div></div>
            <img src={logoDefault} alt="Logotipo Costa Florida Paradise" />
          </div>

          <h2>
            <a
              href="https://www.instagram.com/costafloridaparadise"
              target="_blank"
            >
              @costafloridaparadise
            </a>
          </h2>
        </HeaderContent>
        <OptionsContent>
          <OptionsButtons
            onClick={() => {
              window.open("https://www.costafloridaparadise.com/", "_blank");
            }}
          >
            <a href="https://www.costafloridaparadise.com/" target="_blank">
              <span>Nosso Site</span>
            </a>
          </OptionsButtons>
          <OptionsButtons
            onClick={() => {
              window.open("https://wa.me/5547991511226", "_blank");
            }}
          >
            <a href="https://wa.me/5547991511226" target="_blank">
              <span>WhatsApp</span>
            </a>
          </OptionsButtons>
        </OptionsContent>
        <FooterContent></FooterContent>
      </HomeContent>
    </HomeContainer>
  );
};
